import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import Policy from "./Policy";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import Header from "./Header";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBHMYShe2HTH4F2vGnb6dgnG-LY1xsbBcI",
  authDomain: "fletushkaweb.firebaseapp.com",
  projectId: "fletushkaweb",
  storageBucket: "fletushkaweb.firebasestorage.app",
  messagingSenderId: "834861036740",
  appId: "1:834861036740:web:a03a474d0172dde7d38591",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <div style={{ fontFamily: "Arial, sans-serif" }}>
        <Header />
        {/* <nav style={{ marginBottom: "20px" }}>
          <Link
            to="/"
            style={{
              marginRight: "15px",
              color: "#EAB026",
              textDecorationLine: "none",
            }}
          >
            Home
          </Link>
          <Link
            to="/policy"
            style={{
              marginRight: "15px",
              color: "#EAB026",
              textDecorationLine: "none",
            }}
          >
            Policy
          </Link>
        </nav> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
