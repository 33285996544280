import React, { useState } from "react";
import emailjs from "emailjs-com";

const Home = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      name,
      user_email: email,
      message: subject,
    };
    emailjs
      .send(
        "service_j0cmwqo", // Replace with your EmailJS service ID
        "template_ro7ch7f", // Replace with your EmailJS template ID
        templateParams,
        "E6Z_55PueYFhhiGtL" // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          alert(`Email u dergua!`);
          setName("");
          setEmail("");
          setSubject("");
        },
        (err) => {
          alert(`Diqka shkoi keq`);
        }
      );
  };

  const styles = {
    container: {
      padding: "50px 30px",
      maxWidth: "600px",
      margin: "40px auto",
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: "#fdfdfd",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    },
    card: {
      padding: "40px",
      borderRadius: "12px",
      backgroundColor: "#ffffff",
    },
    title: {
      fontSize: "30px",
      fontWeight: "600",
      marginBottom: "25px",
      color: "#222",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    label: {
      textAlign: "left",
      fontSize: "16px",
      fontWeight: "500",
      color: "#444",
      marginBottom: "10px",
    },
    input: {
      padding: "15px",
      borderRadius: "8px",
      border: "1px solid #ddd",
      fontSize: "16px",
      width: "100%",
      backgroundColor: "#f9f9f9",
      outline: "none",
      transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    },
    inputFocus: {
      borderColor: "#EAB026",
      boxShadow: "0 0 8px rgba(234, 176, 38, 0.5)",
    },
    textarea: {
      padding: "15px",
      borderRadius: "8px",
      border: "1px solid #ddd",
      fontSize: "16px",
      width: "100%",
      backgroundColor: "#f9f9f9",
      resize: "vertical",
      outline: "none",
      transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    },
    button: {
      padding: "15px",
      backgroundColor: "#EAB026",
      color: "#fff",
      border: "none",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "600",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#C4901E",
    },
    footerText: {
      marginTop: "30px",
      fontSize: "14px",
      color: "#777",
      textAlign: "center",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Na kontaktoni</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <label style={styles.label}>Emri</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={styles.input}
              placeholder="Shkruani emrin"
              required
              onFocus={(e) =>
                (e.target.style.borderColor = styles.inputFocus.borderColor)
              }
              onBlur={(e) => (e.target.style.borderColor = "#ddd")}
            />
          </div>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <label style={styles.label}>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.input}
              placeholder="Shkruani email"
              required
              onFocus={(e) =>
                (e.target.style.borderColor = styles.inputFocus.borderColor)
              }
              onBlur={(e) => (e.target.style.borderColor = "#ddd")}
            />
          </div>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <label style={styles.label}>Tema</label>
            <input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              style={styles.input}
              placeholder="Shkruani mesazhin"
              rows="5"
              required
              onFocus={(e) =>
                (e.target.style.borderColor = styles.inputFocus.borderColor)
              }
              onBlur={(e) => (e.target.style.borderColor = "#ddd")}
            />
          </div>
          <button
            type="submit"
            style={styles.button}
            onMouseOver={(e) =>
              (e.target.style.backgroundColor =
                styles.buttonHover.backgroundColor)
            }
            onMouseOut={(e) =>
              (e.target.style.backgroundColor = styles.button.backgroundColor)
            }
          >
            Dergo
          </button>
        </form>
        <p style={styles.footerText}>
          Do t'ju përgjigjemi sa më shpejt të jetë e mundur!
        </p>
      </div>
    </div>
  );
};

export default Home;
