import React from "react";

const Header = () => {
  const styles = {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "15px 20px",
      backgroundColor: "#fdfdfd",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      fontFamily: "'Roboto', sans-serif",
      position: "sticky",
      top: "0",
      zIndex: "1000",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#EAB026",
      textDecoration: "none",
    },
    iconImage: {
      marginRight: "10px",
      width: "120px",
      height: "40px",
    },
    nav: {
      display: "flex",
      gap: "20px",
    },
    link: {
      textDecoration: "none",
      fontSize: "16px",
      fontWeight: "500",
      color: "#555",
      transition: "color 0.3s ease",
    },
    linkHover: {
      color: "#EAB026",
    },
  };

  return (
    <header style={styles.header}>
      {/* Icon Section */}
      <img src={require("./asdf.png")} alt="Logo" style={styles.iconImage} />

      {/* Navigation Links */}
      <nav style={styles.nav}>
        <a
          href="/"
          style={styles.link}
          onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
          onMouseOut={(e) => (e.target.style.color = styles.link.color)}
        >
          Faqja Kryesore
        </a>
        <a
          href="/policy"
          style={styles.link}
          onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
          onMouseOut={(e) => (e.target.style.color = styles.link.color)}
        >
          Kushtet e perdorimit
        </a>
      </nav>
    </header>
  );
};

export default Header;
