import React from "react";

const Policy = () => {
  const styles = {
    container: {
      padding: "40px 20px",
      maxWidth: "800px",
      margin: "0 auto",
      fontFamily: "'Roboto', sans-serif",
      lineHeight: "1.8",
      color: "#333",
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center",
      color: "#EAB026",
    },
    sectionTitle: {
      fontSize: "22px",
      fontWeight: "bold",
      marginTop: "20px",
      marginBottom: "10px",
      color: "#555",
    },
    paragraph: {
      fontSize: "16px",
      marginBottom: "15px",
      textAlign: "justify",
    },
    list: {
      marginLeft: "20px",
    },
    listItem: {
      marginBottom: "10px",
    },
    contactLink: {
      color: "#EAB026",
      textDecoration: "none",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Kushtet dhe Termat</h1>
      <p style={styles.paragraph}>Data e Efektit: 01/12/2024</p>
      <p style={styles.paragraph}>
        Mirë se vini në Fletushkë! Duke përdorur aplikacionin tonë, ju pranoni
        këto Kushte dhe Terma. Ju lutemi, lexoni me kujdes.
      </p>
      <h2 style={styles.sectionTitle}>1. Përmbledhje</h2>
      <p>Aplikacioni Fletushkë i lejon përdoruesit të:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Shikojnë dyqanet dhe fletushkat e tyre.</li>
        <li style={styles.listItem}>
          Ndryshojnë qytetet për të eksploruar dyqane në ato qytete.
        </li>
        <li style={styles.listItem}>
          Marrin njoftime për dyqanet me të cilat kanë ndërvepruar ose që u kanë
          pëlqyer.
        </li>
        <li style={styles.listItem}>
          Nuk kërkohet llogari përdoruesi për të përdorur këtë aplikacion.
        </li>
      </ul>
      <h2 style={styles.sectionTitle}>2. Pranimi i Kushteve</h2>
      <p style={styles.paragraph}>
        Duke aksesuar ose përdorur aplikacionin Fletushkë, ju pranoni këto
        Kushte. Nëse nuk i pranoni, nuk duhet të përdorni aplikacionin.
      </p>
      <h2 style={styles.sectionTitle}>3. Përdorimi i Aplikacionit</h2>
      <p style={styles.paragraph}>
        Duhet të jeni të paktën 13 vjeç për të përdorur aplikacionin Fletushkë.
        Aplikacioni ofrohet vetëm për përdorim personal dhe jo-komercial. Ju
        pranoni të mos abuzoni me aplikacionin, duke përfshirë përpjekjet për të
        prishur funksionalitetin e tij ose për të aksesuar të dhëna pa
        autorizim.
      </p>
      <h2 style={styles.sectionTitle}>4. Pronësia Intelektuale</h2>
      <p style={styles.paragraph}>
        I gjithë përmbajtja në aplikacion, duke përfshirë tekstin, imazhet dhe
        dizajnin, është pronë e Iddowave L.L.C ose licencuesve të tij. Nuk
        lejohet riprodhimi, shpërndarja ose përdorimi i kësaj përmbajtjeje pa
        leje.
      </p>
      <h2 style={styles.sectionTitle}>5. Njoftimet</h2>
      <p style={styles.paragraph}>
        Duke përdorur aplikacionin, ju pranoni të merrni njoftime lidhur me
        dyqanet që ju kanë pëlqyer. Ju mund të menaxhoni këto njoftime në
        cilësimet e pajisjes suaj.
      </p>
      <h2 style={styles.sectionTitle}>6. Përmbajtja e Palëve të Treta</h2>
      <p style={styles.paragraph}>
        Aplikacioni shfaq fletushka dhe përmbajtje të tjera të ofruara nga palë
        të treta. Aplikacionin Fletushkë nuk është përgjegjëse për saktësinë ose
        ligjshmërinë e kësaj përmbajtjeje.
      </p>
      <h2 style={styles.sectionTitle}>7. Kufizimi i Përgjegjësisë</h2>
      <p style={styles.paragraph}>
        Aplikacionin Fletushkë ofrohet "siç është" pa asnjë garanci. Ne nuk jemi
        përgjegjës për ndonjë dëm që lind nga përdorimi i aplikacionit.
      </p>
      <h2 style={styles.sectionTitle}>8. Ndryshimet në Kushtet</h2>
      <p style={styles.paragraph}>
        Ne rezervojmë të drejtën të përditësojmë këto Kushte në çdo kohë.
        Vazhdimi i përdorimit të aplikacionit pas ndryshimeve nënkupton që i
        pranoni Kushtet e përditësuara.
      </p>
      <h2 style={styles.sectionTitle}>9. Kontaktoni</h2>
      <p style={styles.paragraph}>
        Nëse keni ndonjë pyetje, ju lutemi na kontaktoni në{" "}
        <a href="mailto:fletushka12@gmail.com" style={styles.contactLink}>
          fletushka12@gmail.com
        </a>
        .
      </p>
      <h1 style={styles.title}>Politika e Privatësisë</h1>
      <p style={styles.paragraph}>Data e Efektit: 01/12/2024</p>
      <p style={styles.paragraph}>
        Aplikacionin Fletushkë është e angazhuar për të mbrojtur privatësinë
        tuaj. Kjo Politikë e Privatësisë shpjegon se si ne mbledhim, përdorim
        dhe mbrojmë informacionin tuaj.
      </p>
      <h2 style={styles.sectionTitle}>1. Informacioni që Mbledhim</h2>
      <p style={styles.paragraph}>
        Ne nuk mbledhim informacion personal si emra, email-e ose llogari.
        Megjithatë, mund të mbledhim:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Informacioni i Pajisjes: Përfshin llojin e pajisjes, sistemin operativ
          dhe të dhënat e përdorimit të aplikacionit.
        </li>
        <li style={styles.listItem}>
          Të dhënat e Vendndodhjes: Përdoren për të shfaqur dyqane dhe fletushka
          të rëndësishme për qytetin që keni zgjedhur.
        </li>
        <li style={styles.listItem}>
          Preferencat e Njoftimeve: Për t'ju dërguar përditësime lidhur me
          dyqanet që ju pëlqejnë.
        </li>
      </ul>
      <h2 style={styles.sectionTitle}>2. Si e Përdorim Informacionin Tuaj</h2>
      <p style={styles.paragraph}>Ne përdorim të dhënat që mbledhim për të:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Shfaqur dyqane dhe fletushka bazuar në qytetin që keni zgjedhur.
        </li>
        <li style={styles.listItem}>
          Dërguar njoftime lidhur me dyqanet që ju pëlqejnë.
        </li>
        <li style={styles.listItem}>
          Përmirësuar performancën dhe përvojën e përdoruesit në aplikacion.
        </li>
      </ul>
      <h2 style={styles.sectionTitle}>3. Shkëmbimi i të Dhënave</h2>
      <p>
        Ne nuk shesim, shkëmbejmë ose japim me qira informacionin tuaj palëve të
        treta. Megjithatë, mund të ndajmë të dhëna të anonimizuara ose të
        agreguara me: Ofrues të analizave për të përmirësuar performancën e
        aplikacionit. Partnerët e dyqaneve për të kuptuar tendencat e përdorimit
        të aplikacionit.
      </p>
      <h2>4. Shërbimet e Palëve të Treta</h2>
      <p>
        Aplikacioni mund të shfaqë përmbajtje ose lidhje të ofruara nga palë të
        treta. Ne nuk jemi përgjegjës për praktikat e privatësisë së tyre dhe ju
        inkurajojmë të shqyrtoni politikat e tyre.
      </p>
      <h2>5. Ruajtja e të Dhënave</h2>
      <p>
        Ne ruajmë të dhënat vetëm për aq kohë sa është e nevojshme për të
        përmbushur qëllimet e përshkruara në këtë politikë.
      </p>
      <h2>6. Zgjedhjet Tuaja</h2>
      <p>
        Qasja në Vendndodhje: Ju mund ta çaktivizoni aksesin në vendndodhje në
        cilësimet e pajisjes tuaj. Njoftimet: Ju mund të menaxhoni ose
        çaktivizoni njoftimet përmes cilësimeve të pajisjes tuaj. Kërkesat për
        të Dhëna: Meqenëse ne nuk mbledhim të dhëna personale, nuk ka asgjë për
        të fshirë ose aksesuar.
      </p>
      <h2>7. Privatësia e Fëmijëve</h2>
      <p>
        Aplikacioni nuk është i destinuar për përdorues nën moshën 13 vjeç. Ne
        nuk mbledhim me vetëdije informacion nga fëmijët.
      </p>
      <h2>8. Ndryshimet në Këtë Politikë</h2>
      <p>
        Ne mund të përditësojmë këtë Politikë të Privatësisë herë pas here. Ne
        do t'ju njoftojmë për ndryshime të rëndësishme përmes aplikacionit ose
        mjeteve të tjera.
      </p>
      <h2 style={styles.sectionTitle}>9. Kontaktoni</h2>
      <p style={styles.paragraph}>
        Nëse keni pyetje rreth kësaj Politike të Privatësisë, ju lutemi na
        kontaktoni në{" "}
        <a href="mailto:fletushka12@gmail.com" style={styles.contactLink}>
          fletushka12@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default Policy;
